<template>
  <v-container id="home" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="12" class="ma-auto pa-0">
        <v-row class="px-12 my-5">
          <v-col cols="12" md="2" lg="5" order="2" order-md="1" data-aos="slide-right">
            <v-row class="text-center text-md-left my-auto" v-if="statistic_loading">
              <v-col cols="6" md="12" lg="10" class="ma-auto">
                <v-skeleton-loader
                  type="sentences"
                  width="50"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="6" md="12" lg="10" class="ma-auto">
                <v-skeleton-loader
                  type="sentences"
                  width="50"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="text-center text-md-left my-auto" v-else>
              <v-col cols="6" md="12" lg="10" class="ma-auto" v-if="partners" data-aos="fade-right">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <span @click="$navigateToPath('/clients-partners/partners')">
                      <vue-ellipse-progress :progress="partners*100/partners" :legendValue="partners" :color="gradient" :size="100" style="cursor:pointer">
                        <template v-slot:default="{ counterTick }">
                          <div class="ma-auto">
                            <span>{{ counterTick.currentValue }}+</span>
                            <p>{{$t("partners")}}</p>
                          </div>
                          <transition name="fade" mode="out-in">
                            <div v-show="hover" class="ma-auto" style="position:absolute;bottom:-5px;left:0;right:0;">
                              <v-icon class="ma-auto">mdi-arrow-right</v-icon>
                            </div>
                          </transition>
                        </template>
                      </vue-ellipse-progress>
                    </span>
                  </template>
                </v-hover>
              </v-col>
              <v-col cols="6" md="12" lg="10" class="ma-auto" v-if="clients" data-aos="fade-right">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <span @click="$navigateToPath('/clients-partners/clients')">
                      <vue-ellipse-progress :progress="clients*100/clients" :legendValue="clients" :color="gradient" :size="100" style="cursor:pointer">
                        <template v-slot:default="{ counterTick }">
                          <div class="ma-auto">
                            <span>{{ counterTick.currentValue }}+</span>
                            <p>{{$t("clients")}}</p>
                          </div>
                          <transition name="fade" mode="out-in">
                            <div v-show="hover" class="ma-auto" style="position:absolute;bottom:-5px;left:0;right:0;">
                              <v-icon class="ma-auto">mdi-arrow-right</v-icon>
                            </div>
                          </transition>
                        </template>
                      </vue-ellipse-progress>
                    </span>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="10" lg="7" class="ma-auto text-center text-sm-right" order="1" order-md="2" data-aos="slide-left">
            <h1 class="custom-secondary--text font-weight-light">{{$t("hometitle")}}</h1>
            <div class="subtitle-1 custom-secondary--text font-weight-light my-8">{{$t("homedescription")}}</div>
            <v-btn class="custom-accent--text" :title="$t('workwithus')" @click="scrollToId('contact')" dark depressed rounded outlined>{{$t("workwithus")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row class="custom-accent">
          <v-col cols="12" md="10" lg="7" class="ma-auto py-12 text-center white--text">
            <what-we-do :isDark="true"></what-we-do>
          </v-col>
        </v-row>
        <v-row class="text-center text-md-left">
          <v-col cols="12" md="12" lg="10" class="ma-auto my-6 py-12">
            <div class="text-h4 text-uppercase text-center text--secondary" data-aos="fade">{{$t("ourproducts")}}</div>
            <v-row class="ma-0 pa-0" v-if="product_loading">
              <v-col class="ma-auto pa-12" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-block d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-none d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <div class="ma-0 pa-0" v-else>
              <div class="custom-off-white" data-aos="flip-up">
                <swiper class="swiper pa-12" :options="swiperOption">
                  <swiper-slide v-for="product in products" :key="product.id">
                    <v-card class="mx-auto elevation-0 rounded-xl" outlined>
                      <v-img v-if="product.image" :src="storage_url+products_path+product.image" height="175px" :alt="product.alt">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="custom-secondary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-img v-else :src="storage_url+products_path+'products-default-bg.png'" height="175px" :alt="$t('productsdefaultbgalt')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="custom-secondary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-card-title>
                        <span class="v-html-line-clamp-1 mb-3">{{$i18n.locale=='en'?product.titleEN:product.titleFR}}</span>
                      </v-card-title>
                      <v-card-subtitle>
                        <span class="custom-secondary--text v-html-line-clamp-4">{{$i18n.locale=='en'?product.descriptionEN:product.descriptionFR}}</span>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn color="custom-accent" :title="$t('viewmore')" text @click="$navigateToPath('/solutions/products/'+product.id)">
                          {{$t("viewmore")}}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev custom-accent--text" slot="button-prev"></div>
                  <div class="swiper-button-next custom-accent--text" slot="button-next"></div>
                </swiper>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="custom-accent white--text">
          <v-col cols="12" md="10" lg="7" class="ma-auto py-12 text-center">
             <v-row class="pa-0 ma-0" height="auto">
              <v-col cols="12" class="text-center text-md-left" data-aos="slide-right">
                <span class="text-h5 text-uppercase">{{$t("recruitment")}}</span>
              </v-col>
              <v-col cols="12" md="10" lg="8" class="text-center ma-auto">
                <div class="text-h6 font-weight-light text-uppercase" data-aos="fade">{{$t("needstaff")}}</div>
                <div class="text-h6 font-weight-light text-uppercase" data-aos="fade">{{$t("newopportunities")}}</div>
                <div class="pa-6">
                  <div data-aos="zoom-in">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card class="rounded-pill elevation-0 ml-auto px-3 my-2" :class="hover?'custom-accent darken-1':''" :dark="hover" style="transition: all .4s ease-in;" link @click="$navigateToPath('/recruitment/job-seeker')" outlined>
                          <v-card-title class="justify-center">
                            <v-col cols="11" class="d-flex pa-0 ma-0">
                              <v-icon x-large class="mr-2">
                                mdi-briefcase
                              </v-icon>
                              <div class="font-weight-light my-auto">{{$t("jobseeker")}}</div>
                            </v-col>
                            <v-col cols="1" class="d-flex pa-0 ma-0">
                              <transition name="fade-right" mode="out-in">
                                <v-icon v-if="hover" class="ml-auto">mdi-arrow-right</v-icon>
                              </transition>
                            </v-col>
                          </v-card-title>
                        </v-card>
                      </template>
                    </v-hover>
                  </div>
                  <div data-aos="zoom-in">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card class="rounded-pill elevation-0 ml-auto px-3 my-2" :class="hover?'custom-accent darken-1':''" :dark="hover" style="transition: all .4s ease-in;" link @click="$navigateToPath('/recruitment/employer')" outlined>
                          <v-card-title class="justify-center">
                            <v-col cols="11" class="d-flex pa-0 ma-0">
                              <v-icon x-large class="mr-2">
                                mdi-briefcase-account
                              </v-icon>
                              <div class="font-weight-light my-auto">{{$t("employer")}}</div>
                            </v-col>
                            <v-col cols="1" class="d-flex pa-0 ma-0">
                              <transition name="fade-right" mode="out-in">
                                <v-icon v-if="hover" class="ml-auto">mdi-arrow-right</v-icon>
                              </transition>
                            </v-col>
                          </v-card-title>
                        </v-card>
                      </template>
                    </v-hover>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-center text-md-left">
          <v-col cols="12" md="12" lg="10" class="ma-auto my-6 py-12">
            <v-row class="pa-0 ma-0" height="auto">
              <v-col cols="12" class="text-center text-md-right text--secondary" data-aos="slide-left">
                <span class="text-h5 text-uppercase">{{$t("presence")}}</span>
              </v-col>
              <v-col cols="12" md="10" lg="8" class="text-center ma-auto" data-aos="zoom-in">
                <availability-map class="map" height="550px"></availability-map>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h4 text-uppercase">{{$t("hireus")}}</div>
            <v-btn class="my-8" :title="$t('getintouch')" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("getintouch")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            <contact-us-form data-aos="fade-right" :isDark="true" :type="'home'"></contact-us-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../components/Contact/ContactUsForm.vue';
import availabilitymap from '../components/Home/AvailabilityMap.vue';
import whatwedo from '../components/Employer/WhatWeDo.vue';
import { mapState,mapGetters} from "vuex";
export default {
  name: "Home",

  data() {
    return {
      storage_url: process.env.VUE_APP_STORAGEURL,
      products_path: "solutions/products/images/",

      gradient: {
        radial: false,
        colors: [
          {
            color: '#344a4e',
            offset: "0",
            opacity: '1',
          },
          {
            color: '#667274',
            offset: "100",
            opacity: '1',
          },
        ]
      },
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 60,
        notNextTick: true,
        initialSlide: 0,
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        speed: 800,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1524: {
            slidesPerView: 5,
            spaceBetween: 40
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          904: {
            slidesPerView: 3,
            spaceBetween: 60
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 40
          }
        }
      }
    };
  },
  components: {
    'contact-us-form':contactusform,
    'availability-map':availabilitymap,
    'what-we-do':whatwedo,
  },
  created() {
  },
  computed: {
    ...mapGetters('product', {
      products:'getProducts'
    }),
    ...mapState("product", {
      product_loading: "loading"
    }),
    ...mapGetters('statistic', {
      clients:'getClientsStatistics',
      partners:'getPartnersStatistics',
    }),
    ...mapState("statistic", {
      statistic_loading: "loading"
    }),
  }, 
  beforeDestroy () {
  },
  mounted(){
  },
  watch: {
  },
  methods: {
    scrollToId(id){
      let element = document.getElementById(id);
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    
  },
};
</script>
<style scoped>
@import url('../assets/styles/home.css');
</style>